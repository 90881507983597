import {
  faGithubSquare,
  faInstagram,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'

export const EMAIL = 'business@breaker.rocks'

export const SOCIAL_LINKS = [
  {
    href: 'https://www.linkedin.com/in/ryanbreaker/',
    icon: faLinkedin,
  },
  {
    href: 'https://www.instagram.com/ryan.breaker/',
    icon: faInstagram,
  },
  {
    href: 'https://www.youtube.com/channel/UCQFCmyal7qWTyF6jooUeOTA',
    icon: faYoutube,
  },
  {
    href: 'https://github.com/RyanBreaker/',
    icon: faGithubSquare,
  },
]
