import 'preact/devtools'

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLevelUpAlt } from '@fortawesome/free-solid-svg-icons'

import {
  H3,
  H4,
  PopoverCustom,
  SocialLink,
  Subtitle,
  Title,
  Wrapper,
} from '../components'
import { EMAIL, SOCIAL_LINKS } from '../socialLinks'

const IndexPage = () => {
  // A dumb hack to copy the email address to their clipboard
  const onEmailClick = () => {
    const dummy = document.createElement('input')
    document.body.appendChild(dummy)
    dummy.setAttribute('value', EMAIL)
    dummy.select()
    document.execCommand('copy')
    document.body.removeChild(dummy)
  }

  const socialLinks = SOCIAL_LINKS.map(({ href, icon }, index) => (
    <SocialLink key={index} href={href} icon={icon} />
  ))

  return (
    <>
      <Wrapper>
        <header className='container text-center px-4 pt-8'>
          <div className=''>
            <Title>Ryan Breaker</Title>
            <H3>Milwaukee, WI</H3>
            <Subtitle>Aerial Photography and Video Production</Subtitle>
          </div>
          <div className='mt-6'>
            <StaticImage
              src='../images/profile.jpg'
              alt='profile picture'
              imgClassName='rounded-full'
              className='rounded-full border-2 border-gray-300'
            />
            <br />
            <a
              href='https://www.easttroyrr.org/'
              target='_blank'
              rel='noreferrer'
              className='text-base italic font-light transition hover:text-white'
            >
              <span className='underline'>Me running a train</span>&nbsp;
              <FontAwesomeIcon icon={faLevelUpAlt} />
            </a>
          </div>
        </header>

        <main className='container text-center mt-8 text-xl px-4'>
          <div className='space-y-4'>
            <section>
              <ul className='list-inside space-y-1'>
                <li className='leading-tight'>
                  An FAA Part 107 Licensed Commercial Remote Pilot
                  <br />
                  <PopoverCustom buttonText='(What does that mean?)'>
                    <p>
                      FAA Part 107 Licensed Commercial Remote Pilot means we
                      were tested and certified on the required federal
                      regulations to be able to legally act as the Remote
                      Pilot-in-Command of an sUAS (small Unmanned Aerial System,
                      aka "Drone") for commercial purposes.
                    </p>
                    <p className='italic font-light text-base text-center'>
                      Click/tap anywhere to close.
                    </p>
                  </PopoverCustom>
                </li>
              </ul>
            </section>

            <section className='sm:flex sm:justify-center space-y-4 sm:space-y-0'>
              <div className='sm:mx-8 space-y-1'>
                <H3>What do I use?</H3>
                <div>
                  <H4 className='text-center'>Equipment</H4>
                  <ul className='list-inside list-disc sm:text-left'>
                    <li>DJI Air 2S</li>
                    <li>Sony Alpha 6600</li>
                  </ul>
                </div>

                <div>
                  <H4 className='text-center'>Software</H4>
                  <ul className='list-inside list-disc sm:text-left'>
                    <li>Premiere Pro 2021</li>
                    <li>Lightroom Classic</li>
                    <li>Photoshop</li>
                    <li>Affinity Photo & Designer</li>
                  </ul>
                </div>
              </div>

              <div className='sm:mx-8'>
                <H3>What can I do?</H3>
                <ul className='list-inside list-disc sm:text-left'>
                  <li>Photography</li>
                  <li>Photo Editing</li>
                  <li>Video Production</li>
                  <li>Video Editing</li>
                  <li>Color Grading</li>
                  <li>Software Development (my day job)</li>
                </ul>
              </div>
            </section>

            <section>
              <H3>What is aerial footage good for?</H3>
              <ul className='list-inside list-disc'>
                <li>Real Estate</li>
                <li>Business Promotion</li>
                <li>Stock Footage/Photography</li>
                <li>Events and Conferences</li>
                <li>Roof and Structure Inspection</li>
                <li>Almost anything else you can think of (contact me)</li>
              </ul>
            </section>

            <section>
              <H3>How much do I charge?</H3>
              <p className='sm:mx-auto lg:w-3/4 xl:w-1/2'>
                My rate is $20/hour, or for larger projects please contact me for details.
              </p>
            </section>

            <section>
              <H3>How to best contact me?</H3>
              <div>
                Email:
                <button
                  onClick={onEmailClick}
                  className='underline italic transition hover:text-white cursor-pointer'
                >
                  <FontAwesomeIcon icon={faEnvelope} className='mx-2' />
                  {EMAIL}
                </button>
              </div>
              <div className='text-base font-light italic font-light'>
                Click/tap to copy to clipboard.
              </div>
              <div>Or check the other links at the bottom of this page.</div>
              <div>
                And <span className='font-bold underline'>YES</span> my footage
                is available for sale!
              </div>
            </section>

            <section className='flex flex-col justify-items-center space-y-4'>
              <div className='aspect-16-9'>
                <iframe
                  title='2021 Milwaukee, WI 4K Drone Reel by Ryan Breaker'
                  src='https://www.youtube-nocookie.com/embed/973gIrsQ6BU'
                  frameBorder='0'
                  loading='lazy'
                  allowFullScreen
                />
              </div>

              <div className='flex space-x-4'>
                <div className='w-1/2'>
                  <div className='aspect-16-9-gyf'>
                    <iframe
                      title='Milwaukee River Flyover'
                      src='https://gfycat.com/ifr/LightheartedGrandioseBlackrussianterrier'
                      frameBorder='0'
                      scrolling='no'
                      loading='lazy'
                      allowFullScreen
                    />
                  </div>
                </div>
                <div className='w-1/2'>
                  <div className='aspect-16-9-gyf'>
                    <iframe
                      title='Amtrak Milwaukee River Flyover'
                      src='https://gfycat.com/ifr/UnfinishedZanyJuliabutterfly'
                      frameBorder='0'
                      scrolling='no'
                      loading='lazy'
                      allowFullScreen
                    />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>

        <footer className='bg-gray-700 text-gray-300 text-xl px-4 py-6 mt-6 text-center'>
          <div className='flex justify-center text-4xl'>{socialLinks}</div>
          <div className='mt-2'>2021 &copy; Ryan Breaker</div>
        </footer>
      </Wrapper>
    </>
  )
}

export default IndexPage
