import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Popover, Transition } from '@headlessui/react'
import { usePopper } from 'react-popper'

// noinspection JSUnresolvedLibraryURL
export const Wrapper = ({ children }) => (
  <div className='bg-gray-800 text-gray-300 w-full h-full'>
    <Helmet>
      <title>Ryan Breaker Photography</title>
      <link
        rel='stylesheet'
        href='https://use.typekit.net/yjm2mcr.css'
        media='print'
        onLoad="this.media='all'"
      />
    </Helmet>
    {children}
  </div>
)

export const Title = ({ className, children }) => (
  <h1 className={clsx(`text-8xl text-white font-bold`, className)}>
    {children}
  </h1>
)
export const Subtitle = ({ className, children }) => (
  <h2
    className={clsx(
      `text-3xl text-white font-bold italic font-light`,
      className
    )}
  >
    {children}
  </h2>
)
export const H3 = ({ className, children }) => (
  <h3 className={clsx('text-3xl text-white font-bold', className)}>
    {children}
  </h3>
)
export const H4 = ({ className, children }) => (
  <h4 className={clsx('text-xl text-white font-bold', className)}>
    {children}
  </h4>
)

export const SocialLink = ({ href, icon }) => (
  <a
    href={href}
    target='_blank'
    rel='noreferrer'
    className='hover:text-white transition duration-300'
  >
    <FontAwesomeIcon icon={icon} className='mx-4' />
  </a>
)

export const PopoverCustom = ({ buttonText, children }) => {
  const [referenceElement, setReferenceElement] = useState()
  const [popperElement, setPopperElement] = useState()
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: 'bottom',
    modifiers: [
      {
        name: 'flip',
        enabled: false
      }
    ]
  })

  return (
    <Popover className='relative'>
      {({ open }) => (
        <>
          <Popover.Button
            ref={setReferenceElement}
            className='italic font-light underline text-base hover:text-white transition'
          >
            {buttonText}
          </Popover.Button>

          <Transition
            show={open}
            as={Fragment}
            enter='transition-opacity duration-150'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity duration-150'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Popover.Panel static>
              <div
                className='max-w-xs md:max-w-lg p-3 mt-1 text-lg rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 bg-white text-black text-left'
                ref={setPopperElement}
                style={styles.popper}
                {...attributes.popper}
              >
                {children}
              </div>
            </Popover.Panel>
          </Transition>
        </>
      )}
    </Popover>
  )
}
